"use client";
import PropTypes from "prop-types";

import { Block, Grid } from "components/Layout";

import Image from "/components/cms/image";
import Button from "../Button";
import PortableText from "/components/cms/portableText";

import styles from "./CardGallery.module.scss";

const CardGallery = ({ data }) => {
  const { images } = data || {};

  if (!images) {
    return null;
  }

  return (
    <>
      <Grid>
        {images.map((item) => {
          const { button, bottomText, topText } = item || {};

          return (
            <div className={styles.container} key={item._key}>
              {topText && (
                <Block mobileBottom={1} bottom={6}>
                  <PortableText blocks={topText.richText} />
                </Block>
              )}

              <div className={styles.imageContainer}>
                <Image imageObject={item.image} />
              </div>

              {bottomText && (
                <Block top={4}>
                  <PortableText blocks={bottomText.richText} />
                </Block>
              )}

              {button && (
                <Block top={6} mobileBottom={6}>
                  <Button
                    type="link"
                    link={button.href}
                    disabled={button.disabled}
                    primary={button.primary}
                  >
                    {button?.text}
                  </Button>
                </Block>
              )}
            </div>
          );
        })}
      </Grid>
    </>
  );
};

CardGallery.propTypes = {
  data: PropTypes.shape({
    images: PropTypes.array,
    topText: PropTypes.string,
    bottomText: PropTypes.string
  }).isRequired
};

export default CardGallery;
