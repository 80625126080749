"use client";
import PropTypes from "prop-types";

import Block from "../Layout/Block";
import PortableText from "/components/cms/portableText";

import styles from "./WheeWave.module.scss";

function WheeWave({ data = {} }) {
  return (
    <>
      <svg
        className={styles.svg}
        width="1153"
        height="220"
        viewBox="0 0 1153 220"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M38.3504 142.546L0.109375 94.9162L9.27267 91.3134L36.3276 126.245L33.7384 81.9149L40.171 79.419L66.5483 114.611L64.6367 69.937L73.8101 66.376L75.3879 128.229L69.097 130.672L42.7096 96.3259L44.6312 140.113L38.3504 142.546Z"
          fill="#3F7E44"
        />
        <path
          d="M137.56 95.4908L138.966 119.927L130.166 120.47L128.993 100.211C128.518 91.9507 126.212 88.4941 120.073 88.8701C114.338 89.2251 111.334 93.3396 111.728 100.18L112.962 121.535L104.153 122.078L100.785 63.6924L109.584 63.1494L111.031 88.0868C113.134 83.774 117.362 81.2572 122.935 80.9126C131.987 80.3278 136.973 85.2986 137.56 95.4908Z"
          fill="#3F7E44"
        />
        <path
          d="M190.991 111.072L163.431 105.986C162.622 112.116 165.878 116.115 172.169 117.275C176.468 118.068 179.249 117.139 181.201 114.434L189.96 116.053C187.613 123.008 179.734 126.725 170.814 125.086C159.112 122.924 153.195 113.86 155.34 101.443C157.463 89.1938 166.151 82.6462 177.459 84.7348C188.058 86.6876 193.945 95.4178 192.084 106.184C191.811 107.74 191.517 108.962 190.991 111.072ZM183.912 102.717C184.852 96.7753 182.071 92.8697 176.104 91.7732C169.964 90.6454 165.646 93.7469 164.634 99.1667L183.912 102.717Z"
          fill="#3F7E44"
        />
        <path
          d="M238.911 129.806L213.029 118.789C211.006 124.606 213.332 129.232 219.239 131.738C223.285 133.461 226.197 133.158 228.655 130.955L236.888 134.453C233.156 140.719 224.69 142.64 216.316 139.08C205.312 134.391 201.408 124.24 206.06 112.576C210.713 100.911 220.503 96.5773 231.123 101.089C241.075 105.328 245.03 115.145 240.984 125.264C240.397 126.736 239.861 127.864 238.911 129.806ZM233.692 120.084C235.836 114.497 233.935 110.069 228.321 107.678C222.708 105.287 217.702 107.323 215.588 112.388L233.692 120.084Z"
          fill="#3F7E44"
        />
        <path
          d="M282.543 157.438L259.028 141.773C255.994 147.11 257.491 152.091 262.861 155.673C266.533 158.116 269.455 158.377 272.257 156.665L279.731 161.646C274.957 167.118 266.3 167.41 258.694 162.335C248.702 155.673 246.638 144.948 253.273 134.359C259.908 123.77 270.275 121.337 279.923 127.77C288.965 133.795 291.13 144.186 285.385 153.386C284.546 154.691 283.827 155.704 282.543 157.438ZM279.124 146.901C282.209 141.805 281.147 137.085 276.019 133.691C270.892 130.297 265.632 131.341 262.669 135.936L279.124 146.901Z"
          fill="#3F7E44"
        />
        <path
          d="M327.439 186.834L302.842 172.976C300.182 178.521 302.043 183.419 307.646 186.552C311.489 188.714 314.412 188.745 317.093 186.834L324.901 191.241C320.531 197.057 311.914 197.997 303.965 193.517C293.527 187.628 290.715 177.091 296.571 166.021C302.427 154.952 312.612 151.746 322.696 157.427C332.132 162.753 335.035 172.966 329.947 182.563C329.239 183.952 328.592 185.006 327.439 186.834ZM323.272 176.579C326.003 171.264 324.567 166.648 319.227 163.64C313.755 160.56 308.708 162.084 306.078 166.888L323.272 176.579Z"
          fill="#3F7E44"
        />
        <path
          d="M382.167 201.569L354.747 195.742C353.736 201.84 356.932 205.924 363.193 207.229C367.481 208.137 370.272 207.229 372.295 204.639L381.014 206.477C378.485 213.369 370.515 216.92 361.645 214.998C349.994 212.534 344.31 203.313 346.757 190.98C349.185 178.783 358.035 172.475 369.291 174.856C379.83 177.091 385.474 185.957 383.37 196.682C383.077 198.269 382.743 199.48 382.167 201.569ZM375.279 193.037C376.361 187.126 373.691 183.148 367.744 181.895C361.676 180.6 357.246 183.586 356.093 188.975L375.279 193.037Z"
          fill="#3F7E44"
        />
        <path
          d="M437.996 200.524L410.051 202.216C410.638 208.377 414.714 211.479 421.096 211.092C425.455 210.821 427.953 209.244 429.237 206.153L438.128 205.62C437.409 212.93 430.593 218.465 421.541 219.008C409.667 219.729 401.869 212.335 401.151 199.741C400.443 187.314 407.421 178.856 418.891 178.166C429.632 177.508 437.329 184.578 437.956 195.501C438.047 197.089 438.037 198.342 437.996 200.524ZM429.207 194.112C428.782 188.108 425.161 184.975 419.093 185.341C412.873 185.727 409.384 189.789 409.616 195.293L429.207 194.112Z"
          fill="#3F7E44"
        />
        <path
          d="M491.125 187.836L464.03 195.146C465.78 201.067 470.351 203.292 476.541 201.621C480.768 200.482 482.923 198.436 483.621 195.146L492.228 192.828C492.915 200.138 487.272 206.947 478.503 209.307C466.993 212.44 457.941 206.727 454.867 194.52C451.832 182.469 457.061 172.767 468.177 169.781C478.594 166.972 487.474 172.35 490.154 182.939C490.518 184.453 490.751 185.695 491.125 187.836ZM481.284 183.315C479.737 177.519 475.631 175.169 469.764 176.756C463.696 178.375 461.066 183.022 462.341 188.421L481.284 183.315Z"
          fill="#3F7E44"
        />
        <path
          d="M540.835 167.254L515.064 178.553C517.633 184.15 522.468 185.654 528.354 183.075C532.4 181.31 534.22 178.96 534.423 175.608L542.615 172.016C544.324 179.159 539.702 186.73 531.358 190.385C520.405 195.178 510.655 190.928 505.881 179.315C501.107 167.703 504.991 157.469 515.56 152.832C525.472 148.488 535.019 152.467 539.166 162.544C539.763 164.006 540.157 165.196 540.835 167.254ZM530.468 164.267C528.112 158.764 523.722 157.062 518.139 159.505C512.556 161.949 510.432 167.066 512.445 172.172L530.468 164.267Z"
          fill="#3F7E44"
        />
        <path
          d="M587.259 140.949L562.985 155.276C566.161 160.497 571.137 161.437 576.69 158.169C580.483 155.934 582.05 153.365 581.868 150.013L589.595 145.46C592.104 152.352 588.372 160.414 580.493 165.061C570.156 171.149 559.992 168.089 553.943 157.135C547.895 146.18 550.576 135.56 560.558 129.67C569.903 124.167 579.835 126.966 585.084 136.469C585.833 137.847 586.359 138.985 587.259 140.949ZM576.629 139.236C573.666 134.015 569.114 132.887 563.845 135.999C558.576 139.111 557.048 144.426 559.617 149.261L576.629 139.236Z"
          fill="#3F7E44"
        />
        <path
          d="M630.536 109.557L607.88 126.547C611.592 131.403 616.639 131.769 621.807 127.874C625.347 125.221 626.632 122.496 626.096 119.185L633.297 113.786C636.523 120.344 633.661 128.793 626.328 134.296C616.7 141.512 606.282 139.622 599.101 129.419C591.92 119.217 593.468 108.335 602.763 101.37C611.471 94.8431 621.645 96.5035 627.876 105.359C628.756 106.643 629.403 107.709 630.536 109.557ZM619.754 109.066C616.265 104.252 611.612 103.605 606.707 107.291C601.802 110.977 600.841 116.439 603.916 120.95L619.754 109.066Z"
          fill="#3F7E44"
        />
        <path
          d="M677.121 87.6381L651.148 98.4359C653.616 104.075 658.42 105.683 664.357 103.219C668.403 101.537 670.294 99.2191 670.557 95.9088L678.82 92.4731C680.398 99.6577 675.634 107.093 667.229 110.623C656.195 115.207 646.516 110.758 641.944 99.0625C637.373 87.3666 641.449 77.1953 652.109 72.7675C662.091 68.5904 671.568 72.7675 675.523 82.9388C676.109 84.3695 676.484 85.5704 677.121 87.6381ZM666.805 84.5052C664.559 78.9601 660.2 77.1953 654.567 79.5031C648.933 81.811 646.728 86.9071 648.64 92.0345L666.805 84.5052Z"
          fill="#3F7E44"
        />
        <path
          d="M727.458 77.5186L699.826 82.1448C701.02 88.2121 705.379 90.8645 711.68 89.8098C715.999 89.0893 718.335 87.2618 719.306 84.0454L728.085 82.5834C728.085 89.8934 721.855 96.159 712.914 97.6419C701.172 99.6052 692.686 93.0784 690.734 80.6202C688.802 68.3394 694.911 59.1915 706.249 57.2909C716.869 55.5157 725.223 61.7291 726.922 72.5374C727.165 74.1038 727.286 75.3465 727.458 77.5186ZM718.082 72.0675C717.071 66.1464 713.197 63.4104 707.2 64.4129C701.202 65.4154 697.976 69.8536 698.754 75.3048L718.082 72.0675Z"
          fill="#3F7E44"
        />
        <path
          d="M778.706 79.002L750.761 77.2789C750.65 83.4715 754.331 87.0429 760.713 87.4398C765.072 87.7008 767.742 86.3955 769.371 83.5342L778.251 84.0876C776.703 91.2723 769.3 95.9193 760.258 95.3554C748.384 94.6244 741.486 86.3328 742.215 73.7388C742.933 61.3119 750.832 53.7617 762.301 54.4718C773.042 55.1402 779.879 63.0872 779.242 74.0208C779.151 75.6498 778.999 76.8508 778.706 79.002ZM770.716 71.5667C770.979 65.5516 767.783 62.0115 761.725 61.6356C755.504 61.2492 751.56 64.8624 751.165 70.3553L770.716 71.5667Z"
          fill="#3F7E44"
        />
        <path
          d="M828.448 91.616L801.463 83.9197C800.108 89.9452 802.981 94.2372 809.15 95.9916C813.357 97.1925 816.23 96.5346 818.404 94.0387L826.981 96.4824C824.018 103.176 815.856 106.132 807.097 103.646C795.638 100.378 790.561 90.7806 793.817 78.5834C797.023 66.5846 806.268 60.9038 817.342 64.0575C827.719 67.0232 832.797 76.2651 829.975 86.8228C829.57 88.3787 829.166 89.5692 828.448 91.616ZM822.126 82.6143C823.603 76.7977 821.186 72.6415 815.34 70.9706C809.332 69.258 804.75 71.9418 803.203 77.2363L822.126 82.6143Z"
          fill="#3F7E44"
        />
        <path
          d="M874.98 113.693L849.827 100.995C847.42 106.655 849.483 111.437 855.228 114.32C859.152 116.304 862.085 116.199 864.674 114.163L872.664 118.204C868.558 124.219 859.991 125.566 851.85 121.452C841.159 116.053 837.892 105.662 843.263 94.3007C848.563 83.1269 858.656 79.2944 868.983 84.5054C878.652 89.3926 881.989 99.449 877.337 109.286C876.659 110.727 876.052 111.813 874.98 113.693ZM870.368 103.647C872.856 98.2063 871.228 93.6637 865.777 90.9068C860.325 88.1499 855.197 89.8625 852.78 94.7602L870.368 103.647Z"
          fill="#3F7E44"
        />
        <path
          d="M922.548 139.111L896.747 127.864C894.654 133.659 896.97 138.306 902.816 140.855C906.861 142.609 909.754 142.337 912.232 140.155L920.434 143.716C916.652 149.982 908.176 151.799 899.822 148.165C888.869 143.403 885.035 133.21 889.779 121.588C894.462 110.1 904.333 105.715 914.922 110.32C924.844 114.633 928.728 124.48 924.601 134.568C924.055 136.051 923.509 137.168 922.548 139.111ZM917.4 129.347C919.585 123.77 917.714 119.332 912.121 116.899C906.528 114.466 901.511 116.45 899.357 121.494L917.4 129.347Z"
          fill="#3F7E44"
        />
        <path
          d="M983.01 139.205L955.126 141.868C955.915 147.998 960.082 150.963 966.453 150.347C970.802 149.94 973.25 148.259 974.433 145.126L983.303 144.28C982.818 151.59 976.223 157.375 967.121 158.232C955.267 159.359 947.237 152.237 946.114 139.675C945.012 127.279 951.707 118.591 963.146 117.505C973.867 116.46 981.786 123.269 982.757 134.213C982.939 135.78 982.969 137.033 983.01 139.205ZM974.008 133.106C973.391 127.123 969.71 124.115 963.672 124.689C957.462 125.285 954.104 129.462 954.509 134.965L974.008 133.106Z"
          fill="#3F7E44"
        />
        <path
          d="M1036.33 117.65L1010.75 129.409C1013.41 134.954 1018.28 136.374 1024.11 133.69C1028.11 131.852 1029.91 129.513 1030.06 126.109L1038.15 122.37C1039.98 129.482 1035.48 137.126 1027.21 140.937C1016.33 145.929 1006.51 141.856 1001.55 130.328C996.581 118.799 1000.28 108.502 1010.78 103.678C1020.6 99.1558 1030.22 102.967 1034.53 112.961C1035.2 114.413 1035.62 115.593 1036.33 117.65ZM1025.91 114.851C1023.46 109.39 1019.04 107.761 1013.5 110.309C1007.81 112.93 1005.93 118.005 1008.02 123.07L1025.91 114.851Z"
          fill="#3F7E44"
        />
        <path
          d="M1081.7 86.6249L1058.74 103.166C1062.36 108.095 1067.4 108.544 1072.65 104.764C1076.23 102.185 1077.56 99.5426 1077.09 96.1591L1084.39 90.9377C1087.5 97.5689 1084.47 105.954 1077.05 111.312C1067.29 118.329 1056.9 116.241 1049.9 105.892C1042.9 95.543 1044.67 84.7138 1054.09 77.926C1062.92 71.5663 1073.05 73.4252 1079.12 82.406C1080 83.68 1080.61 84.7556 1081.7 86.6249ZM1070.96 85.9148C1067.56 81.0275 1062.87 80.2965 1057.94 83.8262C1053.02 87.3559 1051.87 92.8592 1054.91 97.4018L1070.96 85.9148Z"
          fill="#3F7E44"
        />
        <path
          d="M1121.7 50.4508L1100.96 69.864C1105.16 74.2708 1110.2 74.0411 1114.95 69.6238C1118.18 66.5954 1119.17 63.7549 1118.3 60.5177L1124.9 54.346C1128.78 60.5177 1126.82 69.227 1120.1 75.5135C1111.29 83.7529 1100.73 83.0428 1092.52 73.686C1084.32 64.3293 1084.74 53.3539 1093.25 45.3965C1101.22 37.9299 1111.46 38.4625 1118.63 46.5661C1119.66 47.7461 1120.42 48.7277 1121.7 50.4508ZM1110.96 51.1505C1106.99 46.7436 1102.29 46.6287 1097.81 50.8267C1093.33 55.0247 1092.93 60.5803 1096.46 64.7261L1110.96 51.1505Z"
          fill="#3F7E44"
        />
        <path
          d="M1133.78 34.2856L1105.6 7.06124L1111.67 0.40918L1139.81 27.6127L1133.78 34.2856ZM1140.75 44.4465C1140.09 43.8172 1139.57 43.061 1139.2 42.2213C1138.83 41.3817 1138.63 40.475 1138.6 39.5534C1138.57 38.6317 1138.71 37.7132 1139.03 36.8505C1139.35 35.9877 1139.82 35.1977 1140.43 34.5258C1141.36 33.4989 1142.55 32.7778 1143.87 32.4543C1145.19 32.1307 1146.57 32.2193 1147.84 32.7087C1149.11 33.1981 1150.21 34.0663 1151 35.2029C1151.79 36.3395 1152.24 37.6933 1152.28 39.0922C1152.33 40.4911 1151.97 41.872 1151.25 43.0595C1150.53 44.2469 1149.49 45.1873 1148.25 45.7612C1147.02 46.335 1145.64 46.5164 1144.31 46.2823C1142.97 46.0482 1141.73 45.4092 1140.75 44.4465V44.4465Z"
          fill="#3F7E44"
        />
      </svg>
      <Block mobileTop={8} top={10}>
        {data?.richText && <PortableText blocks={data?.richText} />}
      </Block>
    </>
  );
}

WheeWave.propTypes = {
  data: PropTypes.shape({
    richText: PropTypes.array
  })
};

export default WheeWave;
