"use client";
import React from "react";
import classNames from "classnames";

import { Block, Flow } from "/components/Layout";
import PortableText from "/components/cms/portableText";
import Button from "components/Button";
import Image from "/components/cms/image";

import isEmpty from "../../utils/isEmptyObject";
import styles from "./Box.module.scss";
import { PortableTextBlock } from "@portabletext/types";
import { ImageObject } from "/lib/sanity";

type BoxProps = {
  children?: React.ReactNode;
  data: BoxData;
};

export type BoxData = {
  image: ImageObject;
  richText?: PortableTextBlock[];
  button?: { text: string; href: string; primary?: boolean };
  bgColor?: string;
  smallPadding?: boolean;
};

const Box = ({ data, children }: BoxProps) => {
  const { image, richText, button, bgColor = "", smallPadding = false } = data || {};
  const boxClass = classNames({
    [styles.box]: true,
    [styles[`boxBgColor-${bgColor}`]]: bgColor,
    [styles[`box-small-padding`]]: smallPadding
  });

  return (
    <article className={boxClass}>
      <Flow blockContent>
        {!isEmpty(image) && (
          <Block bottom={6} mobileTop={4}>
            <Image imageObject={image} imageLoading="eager" />
          </Block>
        )}

        {richText && <PortableText blocks={richText} />}

        {button?.text && (
          <Block top={6}>
            <Button link={button.href} primary={button?.primary}>
              {button?.text}
            </Button>
          </Block>
        )}
        {children}
      </Flow>
    </article>
  );
};

export default Box;
