"use client";
import PropTypes from "prop-types";

import { Grid, Block } from "components/Layout";
import Image from "/components/cms/image";

import StyledList from "/components/StyledList";
import BoxWithCta from "/components/BoxWithCta";

import styles from "./Card.module.scss";
import ListWithNumbers from "/components/ListWithNumbers";

const Card = ({ data = {}, children = null }) => {
  const { image, title, text, linkItems, styledList, boxWithCta, listWithNumbers } = data;

  return (
    <Grid>
      {image && <Image imageObject={image} maximagewidth="670" />}
      <div>
        {title && <h2>{title}</h2>}
        {text && (
          <Block top={2}>
            <p>{text}</p>
          </Block>
        )}

        {children && children}

        {linkItems?.length ? (
          <Block style={{ display: "inline-block" }}>
            {linkItems.map((link) => {
              return (
                <div key={link?.text} className={styles.linksContainer}>
                  <a className={styles.link} href={link.href}>
                    {link?.text}
                  </a>
                </div>
              );
            })}
          </Block>
        ) : (
          ""
        )}

        {styledList && (
          <Block top={6}>
            <StyledList data={styledList} />
          </Block>
        )}

        {listWithNumbers && (
          <Block>
            <ListWithNumbers data={listWithNumbers} />
          </Block>
        )}

        {boxWithCta && <BoxWithCta data={boxWithCta} />}
      </div>
    </Grid>
  );
};

Card.propTypes = {
  children: PropTypes.object,
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.object.isRequired,
    linkItems: PropTypes.array
  }).isRequired
};

export default Card;
