import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./Grid.module.scss";

const Grid = ({
  reverse = false,
  verticalCenter = false,
  verticalItemsCenter,
  horizontalCenter = false,
  children
}) => {
  const gridClass = classNames({
    [styles.grid]: true,
    [styles[`grid-reverse`]]: reverse,
    [styles[`grid-verticalItemsCenter`]]: verticalItemsCenter
  });

  const gridItemClass = classNames({
    [styles.gridItem]: true,
    [styles[`gridItem-verticalCenter`]]: verticalCenter,
    [styles[`gridItem-horizontalCenter`]]: horizontalCenter
  });

  return (
    <div className={gridClass}>
      {React.Children.map(children, (child) => (
        <div className={gridItemClass}>{child}</div>
      ))}
    </div>
  );
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  reverse: PropTypes.bool,
  verticalCenter: PropTypes.bool,
  verticalItemsCenter: PropTypes.bool,
  horizontalCenter: PropTypes.bool
};

export default Grid;
