import { createClient } from "next-sanity";

export const projectId = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID || "38sqgmq0";
export const dataset = process.env.NEXT_PUBLIC_SANITY_DATASET || "production";
const apiVersion = process.env.NEXT_PUBLIC_SANITY_API_VERSION || "2021-10-21";
const studioUrl = process.env.NEXT_PUBLIC_SANITY_URL || "https://whee.sanity.studio";

export const client = createClient({
  projectId: projectId,
  dataset: dataset,
  useCdn: false, // set to false because this project uses ISR and static generation. Do not want stale content at build-time
  apiVersion: apiVersion,
  perspective: "published",
  stega: {
    enabled: false,
    studioUrl: studioUrl
  },
  ignoreBrowserTokenWarning: true
});

export function getClient(previewToken: string | undefined = undefined) {
  if (!previewToken) {
    return client;
  }

  return client.withConfig({
    perspective: "previewDrafts",
    stega: {
      enabled: true,
      studioUrl: studioUrl
    },
    token: previewToken
  });
}

export default client;
