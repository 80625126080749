"use client";
import PropTypes from "prop-types";

import { Grid, Block } from "components/Layout";
import PortableText from "/components/cms/portableText";
import Image from "/components/cms/image";

const ColumnsWithImages = ({ data }) => {
  const { columns = {}, text = "" } = data;

  if (!columns) {
    return null;
  }

  return (
    <Block top={8}>
      {text && (
        <Block bottom={6}>
          <h3 align="left">{text}</h3>
        </Block>
      )}
      <Grid>
        {columns?.map((item) => {
          return (
            <Block key={item._key}>
              {item?.image && (
                <Block bottom={4}>
                  <Image size="medium" imageObject={item.image} />
                </Block>
              )}
              <Block style={{ textAlign: "left" }}>
                <PortableText blocks={item.richText} />
              </Block>
            </Block>
          );
        })}
      </Grid>
    </Block>
  );
};

ColumnsWithImages.propTypes = {
  data: PropTypes.shape({
    columns: PropTypes.array,
    text: PropTypes.string
  }).isRequired
};

export default ColumnsWithImages;
