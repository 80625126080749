"use client";
import React from "react";
import classNames from "classnames";

import styles from "./Section.module.scss";

type SectionProps = {
  outer?:
    | "none"
    | "noHorizontalPadding"
    | "xsmall"
    | "small"
    | "medium"
    | "large"
    | "xlarge"
    | "firstSection";
  inner?: "small" | "medium" | "large" | "xlarge";
  color?: "cream-40" | "grey-40" | "black-40" | "green-30" | "green-40" | "none";
  innerNoLimit?: boolean;
  outerNoLimit?: boolean;
  limitedWidth?: boolean;
  limitedWidthSmall?: boolean;
  limitedWidthXSmall?: boolean;
  center?: boolean;
  id?: string;
  addBorder?: boolean;
  children: React.ReactNode;
};

const Section = ({
  outer = "medium",
  inner,
  color = "cream-40",
  limitedWidth = false,
  limitedWidthSmall = false,
  limitedWidthXSmall = false,
  innerNoLimit = false,
  outerNoLimit = false,
  center = false,
  id,
  addBorder,
  children,
  ...rest
}: SectionProps) => {
  const sectionClassOuter = classNames({
    [styles.outer]: true,
    [styles[`outer-size-${outer}`]]: outer,
    [styles[`outer-color-${color}`]]: color,
    [styles[`outer-no-limit`]]: outerNoLimit
  });

  const sectionClassInner = classNames({
    [styles.inner]: true,
    [styles[`inner-size-${inner}`]]: inner,
    [styles[`inner-limited-width-xsmall`]]: limitedWidthXSmall,
    [styles[`inner-limited-width-small`]]: limitedWidthSmall,
    [styles[`inner-limited-width`]]: limitedWidth,
    [styles[`inner-no-limit`]]: innerNoLimit,
    [styles[`inner-center`]]: center,
    [styles[`inner-border`]]: addBorder
  });
  return (
    <section id={id} className={sectionClassOuter} {...rest}>
      <div className={sectionClassInner}>{children}</div>
    </section>
  );
};

export default Section;
