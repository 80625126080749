"use client";
import CardGrid from "/components/CardGrid";
import Button from "/components/Button";
import { Block, Grid } from "/components/Layout";
import Image from "/components/cms/image";
import PortableText from "../cms/portableText";

import styles from "./ArticleList.module.scss";

type Props = {
  data: {
    reverseFirst: boolean;
    alternating: boolean;
    articles: Article[];
  };
};

type Article = {
  title: string;
  intro: { richText: any };
  slug: { current: string };
  content: any;
  thumbnail: { image: any };
};

const ListWithImages = ({ data }: Props) => {
  return (
    <CardGrid reverse>
      {data?.articles?.map((item: Article, i: number) => {
        const { title, intro, slug } = item || {};
        if (!item.title) return null;
        return (
          <Grid key={i} verticalCenter>
            <div className={styles.imageContainer}>
              {item?.thumbnail && <Image imageObject={item?.thumbnail?.image} />}
            </div>
            <Block top={4}>
              <div className={styles.titleContainer}>
                {title && <h3 className="h2">{title}</h3>}

                {/*Show svg on every other item*/}
                {i % 2 == 0 && (
                  <div className={styles.svgContainer}>
                    <svg
                      className={styles.svg}
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.6484 45L44.9973 45"
                        stroke="#FFD7C0"
                        strokeWidth="5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M10.0898 37.1211L29.2827 17.9282"
                        stroke="#FFD7C0"
                        strokeWidth="5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M3 30.3477L3 2.99882"
                        stroke="#FFD7C0"
                        strokeWidth="5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                )}
              </div>
              {intro && (
                <Block top={2}>
                  <PortableText blocks={intro.richText} />
                </Block>
              )}

              <Block top={5}>
                <Button secondary link={`/artikler/${slug?.current}`}>
                  Les mer
                </Button>
              </Block>
            </Block>
          </Grid>
        );
      })}
    </CardGrid>
  );
};

export default ListWithImages;
