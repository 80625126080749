"use client";
import styles from "./SvgWave.module.scss";

function SvgWave() {
  return (
    <>
      <svg
        className={styles.mobileSvg}
        width="1188"
        height="524"
        viewBox="0 0 1188 524"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 13.863C89.58 -13.1328 192.256 -0.160381 270.682 48.0556C353.85 99.1944 405.432 183.345 477.955 246.785C498.766 264.993 522.68 282.069 550.945 286.503C574.469 290.197 598.415 284.721 621.613 279.517C699.833 261.96 788.87 248.657 855.491 290.337C892.87 313.719 917.598 351.625 955.617 374.117C1022.46 413.665 1109.87 395.998 1186.54 377.01V524H0V13.863Z"
          fill="#ABDD96"
        />
        <path
          d="M1158.99 265.132C1161.27 264.392 1163.66 264.539 1165.61 265.68C1167.55 266.816 1169.13 268.977 1169.71 272.397C1169.71 272.398 1169.71 272.398 1169.71 272.399L1175.36 306.431C1175.36 306.431 1175.36 306.431 1175.36 306.432C1175.13 306.484 1174.83 306.558 1174.52 306.643C1173.88 306.814 1173.12 307.036 1172.48 307.244C1171.88 307.438 1171.11 307.712 1170.45 307.963C1170.12 308.089 1169.81 308.21 1169.57 308.314C1169.54 308.324 1169.52 308.335 1169.49 308.345L1154.5 277.348C1154.5 277.347 1154.5 277.347 1154.5 277.346C1151.82 271.731 1154.6 266.56 1158.99 265.132ZM1183.74 318.371C1184.91 321.944 1182.75 325.836 1179.01 327.05C1175.28 328.262 1171.33 326.363 1170.17 322.783C1168.97 319.104 1171.04 315.166 1174.75 313.958C1178.48 312.748 1182.55 314.7 1183.74 318.371ZM1095.81 353.406L1066.49 304.504L1077.5 300.928L1097.84 338.906L1098.78 338.669L1098.72 294.03L1110.12 290.326L1136.32 326.473L1137.22 326.111L1131.35 283.429L1142.36 279.852L1147.39 336.646L1134.76 340.751L1109.32 305.714L1108.42 304.466L1108.42 306.008L1108.44 349.302L1095.81 353.406Z"
          fill="#373737"
          stroke="black"
        />
      </svg>

      <svg
        className={styles.svg}
        width="2686"
        height="496"
        viewBox="0 0 2686 496"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 18.1096C202.708 -17.1556 435.051 -0.209515 612.519 62.7762C800.716 129.58 917.44 209.508 1081.55 292.382C1128.64 316.166 1182.76 328.473 1246.72 334.266C1299.95 339.091 1354.14 331.939 1406.63 325.139C1583.63 302.204 1785.11 284.827 1935.87 339.274C2020.45 369.819 2076.41 419.337 2162.44 448.718C2313.68 500.38 2511.48 467.301 2685 442.497L2685 496H0.00352903L0 18.1096Z"
          fill="#ABDD96"
        />
        <path
          d="M2656.99 319.132C2659.27 318.392 2661.66 318.539 2663.61 319.68C2665.55 320.816 2667.13 322.977 2667.71 326.397C2667.71 326.398 2667.71 326.398 2667.71 326.399L2673.36 360.431C2673.36 360.431 2673.36 360.431 2673.36 360.432C2673.13 360.484 2672.83 360.558 2672.52 360.643C2671.88 360.814 2671.12 361.036 2670.48 361.244C2669.88 361.438 2669.11 361.712 2668.45 361.963C2668.12 362.089 2667.81 362.21 2667.57 362.314C2667.54 362.324 2667.52 362.335 2667.49 362.345L2652.5 331.348C2652.5 331.347 2652.5 331.347 2652.5 331.346C2649.82 325.731 2652.6 320.56 2656.99 319.132ZM2681.74 372.371C2682.91 375.944 2680.75 379.836 2677.01 381.05C2673.28 382.262 2669.33 380.363 2668.17 376.783C2666.97 373.104 2669.04 369.166 2672.75 367.958C2676.48 366.748 2680.55 368.7 2681.74 372.371ZM2593.81 407.406L2564.49 358.504L2575.5 354.928L2595.84 392.906L2596.78 392.669L2596.72 348.03L2608.12 344.326L2634.32 380.473L2635.22 380.111L2629.35 337.429L2640.36 333.852L2645.39 390.646L2632.76 394.751L2607.32 359.714L2606.42 358.466L2606.42 360.008L2606.44 403.302L2593.81 407.406Z"
          fill="#373737"
          stroke="black"
        />
      </svg>
    </>
  );
}

export default SvgWave;
