"use client";
import React from "react";
import classNames from "classnames";

import { Block, Flow } from "/components/Layout";
import PortableText from "/components/cms/portableText";

import styles from "./WhatIsIncluded.module.scss";

type WhatIsIncludedProps = {
  data: {
    title: string;
    gridItems: GridItemProps[];
  };
};

type GridItemProps = { bgColor: any; _key: React.Key | null | undefined; richText: any };

const WhatIsIncluded = ({ data }: WhatIsIncludedProps) => {
  const { gridItems, title } = data || {};

  return (
    <div>
      <Flow blockContent>
        {title && <h2>{title}</h2>}

        <Block top={6}>
          <div className={styles.gridContainer}>
            {gridItems?.map((item: GridItemProps) => {
              const boxClass = classNames({
                [styles.gridItem]: true,
                [styles[`boxBgColor-${item?.bgColor}`]]: item?.bgColor
              });

              return (
                <article key={item._key} className={boxClass}>
                  <PortableText blocks={item.richText} />
                </article>
              );
            })}
          </div>
        </Block>
      </Flow>
    </div>
  );
};

export default WhatIsIncluded;
