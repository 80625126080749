"use client";
import { useState } from "react";
import PropTypes from "prop-types";

import styles from "./Table.module.scss";

function Table({ data }) {
  const [showDescription, setShowDescription] = useState(false);
  const [currentRowKey, setCurrentRow] = useState("");

  if (!data) {
    return null;
  }

  const onRowClick = (rowKey) => {
    // hide current row description
    if (rowKey === currentRowKey && showDescription) {
      setShowDescription(false);
      return;
    }
    // open current row description
    setShowDescription(true);
    setCurrentRow(rowKey);
  };

  const showArrow = data.showArrow;

  return (
    <table className={styles.table}>
      <tbody>
        {data.tableContent.rows.map((rows, index) => {
          return (
            <tr
              onClick={() => onRowClick(rows._key)}
              key={`tr-${rows._key}-${index}`}
              className={styles.tr}
              aria-expanded={rows._key === currentRowKey}
            >
              {index === 0
                ? rows.cells.map((row, i) => {
                    return (
                      <th className={styles.th} key={`th-${i}`}>
                        <h3>{row}</h3>
                      </th>
                    );
                  })
                : rows.cells.map((row, i) => {
                    const key = `td-${i}`;
                    const rowTitle = row.split("#")[0];
                    const rowDescription = row.split("#")[1];
                    const showRowDescription = rows._key === currentRowKey && showDescription;

                    return (
                      <>
                        <td key={key} className={styles.td}>
                          <div style={{ display: "flex" }}>
                            {i === 0 && showArrow && (
                              <div className={styles.svg}>
                                <svg
                                  style={{
                                    transform: showRowDescription ? "rotate(90deg)" : "rotate(0deg)"
                                  }}
                                  width="21"
                                  height="21"
                                  viewBox="0 0 21 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.83973 19.1522C9.42552 19.738 10.3753 19.738 10.9611 19.1522L18.7392 11.3741C18.8101 11.3032 18.8724 11.227 18.9261 11.1467C19.0064 11.093 19.0826 11.0307 19.1534 10.9598C19.7392 10.3741 19.7392 9.42431 19.1534 8.83852L11.3753 1.06035C10.7895 0.474561 9.83973 0.474561 9.25394 1.06035C8.66816 1.64613 8.66816 2.59588 9.25394 3.18167L15.9715 9.89918L8.83973 17.0309C8.25394 17.6167 8.25394 18.5664 8.83973 19.1522Z"
                                    fill="#767676"
                                  />
                                </svg>
                              </div>
                            )}
                            <div>
                              <p>{rowTitle}</p>
                              {showRowDescription && (
                                <div className={styles.tdDescription}>
                                  <p className="text-label">{rowDescription}</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                      </>
                    );
                  })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

Table.propTypes = {
  data: PropTypes.object.isRequired
};

export default Table;
