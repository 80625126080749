"use client";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Block } from "components/Layout";
import Button from "components/Button";

import isEmpty from "../../utils/isEmptyObject";
import PortableText from "/components/cms/portableText";

import styles from "./BoxWithCta.module.scss";

const BoxWithCta = ({ data = {}, children = [] }) => {
  const { button = {}, bgColor = "", text = "", richText = null } = data;

  const boxClass = classNames({
    [styles.box]: true,
    [styles[`boxBgColor-${bgColor}`]]: bgColor
  });

  return (
    <article className={boxClass}>
      {richText && <PortableText blocks={richText} />}
      {!isEmpty(button) && (
        <Block top={5}>
          <div className={styles.buttonContainer}>
            <Button link={button.href} primary={button.primary}>
              {button?.text}
            </Button>

            <Block top={4}>{text && <p className="text-label">{text}</p>}</Block>
          </div>
        </Block>
      )}
      {children}
    </article>
  );
};

BoxWithCta.propTypes = {
  data: PropTypes.shape({
    bgColor: PropTypes.string,
    richText: PropTypes.array,
    button: PropTypes.object,
    text: PropTypes.string
  }),
  children: PropTypes.node
};

export default BoxWithCta;
