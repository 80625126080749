"use client";
import { Block } from "/components/Layout";
import Button from "/components/Button";

import styles from "./Locations.module.scss";

type LocationsProps = {
  data: {
    title: string;
    text: string;
    button: { text: string; primary: boolean; href: string };
  };
};
//TODO update this component to more generic one. We use it for booking test ride only in one location.
// To book a test ride we could use BoxWithCta component and extend styles "boxShadow", "center items"
const Locations = ({ data }: LocationsProps) => {
  const { title, text, button } = data;
  const testRideOsloUrl = "https://calendly.com/wheetreff/provesykling";

  const openTestRideBookingPage = () => {
    window.open(testRideOsloUrl, "_blank", "noopener,noreferrer");
  };

  return (
    <div className={styles.container}>
      {title && <h3>{title}</h3>}
      {text && (
        <Block top={4}>
          <p>{text}</p>
        </Block>
      )}

      <div className={styles.locationsContainer}>
        {button?.text && (
          <Block top={6}>
            <Button {...button} onClick={openTestRideBookingPage} type="button">
              {button.text}
            </Button>
          </Block>
        )}
      </div>
    </div>
  );
};

export default Locations;
