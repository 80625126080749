"use client";
import PropTypes from "prop-types";

import styles from "./Video.module.scss";

const Video = ({ url }) => {
  const id = findYoutubeId(url);
  const isYoutube = url.includes("youtube.com");
  const videoUrl = isYoutube
    ? `https://www.youtube-nocookie.com/embed/${id}?rel=0&modestbranding=1`
    : url;

  return (
    <div className={styles.videoWrap}>
      <iframe
        src={videoUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Video"
      />
    </div>
  );
};

Video.propTypes = {
  url: PropTypes.string.isRequired
};

export default Video;

const findYoutubeId = (url = "") => {
  if (url.includes("youtube.com") || url.includes("youtu.be")) {
    const regex =
      /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    const result = url.match(regex) || [];
    const id = result[1];
    if (id) {
      return id;
    }
  }
  console.error("Could not find id of url:", url);
  return null;
};
