"use client";
import { Block } from "components/Layout";

import React from "react";
import Img from "next/image";

import { ImageUrlBuilder, useNextSanityImage } from "next-sanity-image";
import sanityClient from "lib/sanity/client";
import { ImageObject } from "/lib/sanity";

type Props = {
  imageLoading?: "lazy" | "eager";
  imageObject: ImageObject;
  imagestyle?: string;
  className?: string;
};

const customImageBuilder = (imageUrlBuilder: ImageUrlBuilder) => {
  return imageUrlBuilder.fit("max").auto("format").quality(50);
};

const Image = ({ imageObject, imageLoading = "lazy", ...props }: Props) => {
  const imageProps = useNextSanityImage(sanityClient, imageObject, {
    imageBuilder: customImageBuilder
  });
  const { caption = "", altText = "", asset } = imageObject || {};

  if (!imageObject?.asset) {
    return null;
  }

  return (
    <figure {...props}>
      <Img
        {...imageProps}
        alt={altText}
        sizes={`(max-width: 800px) 100vw, 800px`}
        placeholder={asset?.metadata?.lqip !== undefined ? "blur" : "empty"}
        blurDataURL={asset?.metadata?.lqip}
        loading={imageLoading}
        unoptimized
      />
      {caption && (
        <Block top={3}>
          <figcaption>
            <span className="text-caption">{caption}</span>
          </figcaption>
        </Block>
      )}
    </figure>
  );
};

export default Image;
