"use client";
import classNames from "classnames";

import styles from "./Block.module.scss";

type Props = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  mobileTop?: number;
  mobileBottom?: number;
  children: any;
  className?: string;
};

const Block = ({ top, bottom, left, right, mobileTop, mobileBottom, children, ...rest }: Props) => {
  const blockClass = classNames({
    [styles.block]: true,
    [styles[`-top-${top}`]]: top,
    [styles[`-bottom-${bottom}`]]: bottom,
    [styles[`-left-${left}`]]: left,
    [styles[`-right-${right}`]]: right,
    [styles[`-mobile-top-${mobileTop}`]]: mobileTop,
    [styles[`-mobile-bottom-${mobileBottom}`]]: mobileBottom
  });
  return (
    <div className={blockClass} {...rest}>
      {children}
    </div>
  );
};

export default Block;
