"use client";
import PropTypes from "prop-types";

import { Grid, Block } from "components/Layout";
import PortableText from "/components/cms/portableText";

const BannerWithUps = ({ data }) => {
  const { columns = {} } = data;

  if (!columns) {
    return null;
  }

  return (
    <div>
      <Grid>
        {columns?.map((item) => {
          return (
            <Block key={item._key}>
              <Block className="text-normal">
                <PortableText blocks={item.richText} />
              </Block>
            </Block>
          );
        })}
      </Grid>
    </div>
  );
};

BannerWithUps.propTypes = {
  data: PropTypes.shape({
    columns: PropTypes.array
  }).isRequired
};

export default BannerWithUps;
