"use client";
import Link from "next/link";

import { Block } from "/components/Layout";
import Button from "/components/Button";

import styles from "./ListWithNumbers.module.scss";
import classNames from "classnames";

type Props = {
  data: {
    title: string;
    listWithNumbersItem: [];
    button: {
      text: string;
      link: string;
      href: string;
      primary: boolean;
      secondary: boolean;
    };
    verticalDirection?: boolean;
  };
};

const ListWithNumbers = ({ data }: Props) => {
  const { title = "", listWithNumbersItem = [], button } = data;
  const gridClass = classNames({
    [styles.grid]: true,
    [styles[`grid-vertical`]]: data?.verticalDirection
  });

  return (
    <>
      {title && (
        <Block bottom={9}>
          <h2>{title}</h2>
        </Block>
      )}

      <ol className={gridClass}>
        {listWithNumbersItem.map((item, index) => {
          const { title: itemTitle = "", text = "", _key = "" } = item;
          const number = index + 1;

          return (
            <li className={styles.gridItem} key={_key}>
              {itemTitle && (
                <h3>
                  <div className={styles.gridItemHeaderContainer}>
                    <div className={styles.number}>{number}</div>
                    <p className="body-quote font-family-medium">{itemTitle}</p>
                  </div>
                </h3>
              )}
              {text && (
                <Block top={3}>
                  <p className="body-text">{text}</p>
                </Block>
              )}
            </li>
          );
        })}
      </ol>

      {button?.href && (
        <Block top={6} mobileBottom={6}>
          {button?.primary || button?.secondary ? (
            <Button link={button.href} primary={button?.primary} secondary={button?.secondary}>
              <span className="text-button">{button.text}</span>
            </Button>
          ) : (
            <Link className="link" href={button.href}>
              <strong>{button?.text}</strong>
            </Link>
          )}
        </Block>
      )}
    </>
  );
};

export default ListWithNumbers;
