import classNames from "classnames";

import styles from "./StyledList.module.scss";
import { Block } from "../Layout";

import { vercelStegaSplit } from "@vercel/stega";

import type { StyledList } from "/lib/sanity";

const StyledList = ({ data }: { data: StyledList }) => {
  if (!data) {
    return null;
  }

  const bgColor = vercelStegaSplit(data.bgColor);

  const styledListClass = classNames({
    [styles.row]: true,
    [styles[`bgColor-${bgColor.cleaned}`]]: bgColor.cleaned
  });

  /**
   * This operation cleans up stega-encoded data, and splits it into cleaned and encoded.
   * Stega-encoded data is a part of Sanity Presentation/Live Preview. The encoded data is how Presentation knows which fields are clickable/editable.
   * Docs where Sanity explains how to avoid styling related issues because of stega-encoding: https://www.sanity.io/docs/loaders-and-overlays#89c5348e1e65
   */

  const rows = data.tableContent.rows.map((row) => {
    const cells = row.cells.map((cell) => {
      return vercelStegaSplit(cell);
    });

    return {
      ...row,
      cells: cells
    };
  });

  return (
    <div className={styles.container}>
      {rows.map((row, i) => {
        return row.cells.map((row, j) => {
          const { cleaned } = row;

          if (!cleaned) {
            return null;
          }

          return (
            <>
              <Block key={`${i}:${j}`} className={styledListClass}>
                <svg className={styles.svg} width="43" height="52" viewBox="0 0 43 52" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.08525 26.6043C0.480538 27.6767 0.0489981 29.8469 1.12138 31.4517L13.546 50.0439C14.6184 51.6487 16.7886 52.0802 18.3934 51.0078C19.067 50.5576 19.5339 49.914 19.7696 49.1999C19.9875 48.9711 20.1779 48.7037 20.3323 48.3999L41.7374 6.29364C42.612 4.57314 42.0004 2.33939 40.3713 1.30443C38.7422 0.269473 36.7125 0.825217 35.8379 2.54572L16.1248 41.3235L6.93257 27.5682C5.86019 25.9635 3.68997 25.532 2.08525 26.6043Z"
                    fill="#3F7E44"
                  />
                </svg>
                <p className="body-quote font-family-medium">{cleaned}</p>
                {/* {encoded} */}
              </Block>
            </>
          );
        });
      })}
      {bgColor.encoded ? <span style={{ display: "none" }}>{bgColor.encoded}</span> : null}
    </div>
  );
};

export default StyledList;
