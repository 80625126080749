"use client";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./CardGrid.module.scss";

const CardGrid = ({ reverse = false, reverseFirst, children }) => {
  const cardGridClass = classNames({
    [styles.cardGrid]: true,
    [styles.reverse]: reverse && !reverseFirst,
    [styles["reverse-first"]]: reverseFirst
  });
  return <div className={cardGridClass}>{children}</div>;
};

CardGrid.propTypes = {
  reverse: PropTypes.bool,
  reverseFirst: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default CardGrid;
