"use client";
import PropTypes from "prop-types";

import isEmpty from "utils/isEmptyObject";

import { Block } from "components/Layout";

import StyledList from "../StyledList";
import BoxWithCta from "../BoxWithCta";
import PortableText from "/components/cms/portableText";

import styles from "./StyledListWithCtaBox.module.scss";

const StyledListWithCtaBox = ({ data }) => {
  const { styledList = {}, boxWithCta = {} } = data;

  return (
    <div className={styles.container}>
      <Block style={{ textAlign: "left" }} bottom={4}>
        {styledList?.richText && <PortableText blocks={styledList?.richText?.richText} />}
      </Block>
      <div className={styles.contentContainer}>
        <article className={styles.article}>
          {!isEmpty(styledList) && <StyledList data={styledList} />}
        </article>

        <article>
          {!isEmpty(boxWithCta) && (
            <div className={styles.boxCtaContainer}>
              <BoxWithCta data={boxWithCta} />
            </div>
          )}
        </article>
      </div>
    </div>
  );
};

StyledListWithCtaBox.propTypes = {
  data: PropTypes.shape({
    boxWithCta: PropTypes.object,
    styledList: PropTypes.object
  }).isRequired
};

export default StyledListWithCtaBox;
