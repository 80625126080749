"use client";
import { NextSeo } from "next-seo";

type SeoProps = {
  isFrontpage?: boolean;
  noindex?: boolean;
  nofollow?: boolean;
  page: {
    intro?: {};
    title?: string;
    seo?: {
      title: string;
      description: string;
      hidden: boolean;
    };
  };
};
const Seo = ({ page, isFrontpage = false, ...rest }: SeoProps) => {
  const title = page?.seo?.title || page?.title || "Whee!";
  const wheeTitle = isFrontpage ? "" : " | Whee!";

  const description =
    page?.seo?.description || "En hverdagsmaskin med det du trenger til fast månedspris";

  const defaultImage = [
    {
      url: "https://whee.no/images/some-default-image.jpg",
      width: 2000,
      height: 1739,
      alt: "Whee!"
    }
  ];

  const images = defaultImage;
  return (
    <NextSeo
      title={`${title}${wheeTitle}`}
      description={description}
      openGraph={{
        images
      }}
      noindex={page?.seo?.hidden}
      nofollow={page?.seo?.hidden}
      {...rest}
    />
  );
};

export default Seo;
