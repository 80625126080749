"use client";
import { Block } from "/components/Layout";

import styles from "./ScrollToTopButton.module.scss";

type Props = {
  data: {
    text: string;
  };
};

const ScrollToTopButton = ({ data }: Props) => {
  const { text } = data || {};

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className={styles.container}>
      <svg
        onClick={scrollToTop}
        className={styles.button}
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
      >
        <path
          d="M20.5 30.5L28.5 23.5L36.5 30.5"
          stroke="#FFFCF4"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <Block top={4}>
        <p className="body-quote font-family-medium color--green-40">{text}</p>
      </Block>
    </div>
  );
};

export default ScrollToTopButton;
