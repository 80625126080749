"use client";
import PropTypes from "prop-types";

import { Block, Grid } from "components/Layout";

import Image from "/components/cms/image";

import styles from "./ImageGallery.module.scss";

const ImageGallery = ({ data }) => {
  if (!data?.images) {
    return null;
  }

  return (
    <>
      <Block bottom={6}>
        <h2 align="center">{data?.title}</h2>
      </Block>
      <Grid horizontalCenter>
        {data?.images.map((item) => {
          return (
            <div style={{ width: "70%" }} className={styles.imageContainer} key={item._key}>
              <a className={styles.img} href={item.href} target="_blank" rel="noreferrer">
                <Image imageObject={item.image} />
              </a>
              <Block top={2}>{item.title && <p>{item.title}</p>}</Block>
            </div>
          );
        })}
      </Grid>
    </>
  );
};

ImageGallery.propTypes = {
  data: PropTypes.shape({
    images: PropTypes.array,
    title: PropTypes.string
  }).isRequired
};

export default ImageGallery;
