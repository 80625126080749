"use client";
import React, { useState } from "react";
import classNames from "classnames";

import { Flow } from "/components/Layout";
import PortableText from "/components/cms/portableText";

import styles from "./Faq.module.scss";
import { PortableTextBlock } from "@portabletext/types";

type FaqProps = {
  data: {
    title: string;
    faq: FaqItemProps[];
  };
};

type FaqItemProps = {
  _id: string;
  title: string;
  question: string;
  answer: { richText: PortableTextBlock[] };
};

const Faq = ({ data }: FaqProps) => {
  const [showDescription, setShowDescription] = useState(false);
  const [currentRowKey, setCurrentRow] = useState("");

  const { faq = [], title = "" } = data;

  const onRowClick = (rowKey: string) => {
    setCurrentRow(rowKey);
    // hide current row description
    if (rowKey === currentRowKey && showDescription) {
      setShowDescription(false);
      return;
    }
    // open current row description
    setShowDescription(true);
    setCurrentRow(rowKey);
  };

  return (
    <div className={styles.container}>
      <Flow>
        {title && <h3>{title}</h3>}
        {faq?.map((item) => {
          const showRowDescription = item._id === currentRowKey && showDescription;

          const svgClass = classNames({
            [styles.svg]: true,
            [styles.svgTransform]: showRowDescription
          });

          return (
            <article onClick={() => onRowClick(item._id)} className={styles.item} key={item._id}>
              <div className={styles.separator} />
              <div className={styles.header}>
                <p className={`${showRowDescription ? "highlight" : undefined} body-quote`}>
                  {item.question}
                </p>

                <div className={svgClass}>
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.83973 19.1522C9.42552 19.738 10.3753 19.738 10.9611 19.1522L18.7392 11.3741C18.8101 11.3032 18.8724 11.227 18.9261 11.1467C19.0064 11.093 19.0826 11.0307 19.1534 10.9598C19.7392 10.3741 19.7392 9.42431 19.1534 8.83852L11.3753 1.06035C10.7895 0.474561 9.83973 0.474561 9.25394 1.06035C8.66816 1.64613 8.66816 2.59588 9.25394 3.18167L15.9715 9.89918L8.83973 17.0309C8.25394 17.6167 8.25394 18.5664 8.83973 19.1522Z"
                      fill="#767676"
                    />
                  </svg>
                </div>
              </div>
              {showRowDescription && (
                <div className="text-label">
                  <PortableText blocks={item.answer.richText} />
                </div>
              )}
            </article>
          );
        })}
        <div className={styles.separator} />
      </Flow>
    </div>
  );
};

export default Faq;
