import React from "react";
import classNames from "classnames";

import styles from "./Spinner.module.scss";

type Props = {
  color?: "white" | "green";
};

const Spinner = ({ color }: Props) => {
  const spinnerClass = classNames(styles.spinner, {
    [styles[`spinner-color-${color}`]]: color
  });

  return <div className={spinnerClass} />;
};

export default Spinner;
