"use client";
import Box from "/components/Box";
import Image from "/components/cms/image";

import isEmpty from "../../utils/isEmptyObject";

import styles from "./ArticleWithBackgroundEffect.module.scss";
import { BoxData } from "/components/Box/Box";
import { ImageObject } from "/lib/sanity";

type Props = {
  data: {
    image: ImageObject;
    box: BoxData;
  };
};

const ArticleWithBackgroundEffect = ({ data }: Props) => {
  const { box, image } = data || {};

  if (!box) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.flexContainer}>
        <div className={styles.contentContainer}>
          {!isEmpty(image) && (
            <div className={styles.imageContainer}>
              <Image imageObject={image} />
            </div>
          )}
          {!isEmpty(box) && (
            <div className={styles.boxContainer}>
              <Box data={box} />
            </div>
          )}
        </div>

        <div className={styles.svgContainer}>
          <svg
            className={styles.svgMobile}
            width="375"
            height="459"
            viewBox="0 0 375 459"
            fill="none"
          >
            <path
              d="M-26.8189 451.341C24.1967 462.459 264.226 460.606 339.919 451.341C376.253 444.225 407.752 444.48 447.876 312.179C471.725 233.541 405.197 71.1838 394.29 53.368C361.615 3.24566e-06 253.21 -2.0895e-05 137.596 -1.40941e-05C21.981 -7.29312e-06 -79.3597 19.8224 -93.4757 63.0251C-104.031 95.3299 -103.484 265.742 -98.818 312.179C-94.1519 358.616 -90.5884 437.444 -26.8189 451.341Z"
              fill="#ABDD96"
            />
          </svg>
          <svg
            className={styles.svgDesktop}
            width="1062"
            height="904"
            viewBox="0 0 1062 904"
            fill="none"
          >
            <path
              d="M143.807 888C241.39 909.874 700.519 906.228 845.307 888C914.807 874 975.058 874.501 1051.81 614.203C1097.42 459.486 970.172 140.052 949.307 105C886.807 0.000191975 679.449 0.000204172 458.3 0.000217181C237.152 0.00023019 43.3066 39.0002 16.3053 124C-3.88503 187.559 -2.83867 522.839 6.08662 614.203C15.0119 705.566 21.8283 860.658 143.807 888Z"
              fill="#ABDD96"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ArticleWithBackgroundEffect;
