"use client";
import { Grid, Block } from "/components/Layout";
import Image from "/components/cms/image";
import Button from "/components/Button";

import styles from "./ThreeColumns.module.scss";
import { ImageObject } from "/lib/sanity";

type Props = {
  data: {
    columns: Array<ColumnItem>;
    title: string;
    enableBorderRadiusEffect: boolean;
    centerContent: boolean;
  };
};
type ColumnItem = {
  _key: string;
  image: ImageObject;
  title: string;
  description: string;
  button: { text: string; secondary?: boolean; primary?: boolean; href: string };
  sectionId: string;
};

const ThreeColumns = ({ data }: Props) => {
  const { columns, title, enableBorderRadiusEffect, centerContent } = data;
  const borderRadius = enableBorderRadiusEffect ? undefined : 12; // css variable style name "--border-radius-2"

  if (columns.length < 0) {
    return null;
  }

  return (
    <Block top={8}>
      {title && (
        <Block bottom={6}>
          <h2>{title}</h2>
        </Block>
      )}

      <Grid>
        {columns?.map((item: ColumnItem) => {
          return (
            <Block key={item._key}>
              <Block>
                {item?.image && (
                  <div className={styles.imageContainer}>
                    <div className={styles.image}>
                      <Block bottom={4}>
                        <Image imageObject={item.image} />
                      </Block>
                    </div>
                  </div>
                )}

                <div
                  style={{
                    borderRadius,
                    position: item?.image ? "relative" : "static",
                    alignItems: centerContent ? "center" : "flex-start",
                    textAlign: centerContent ? "center" : "left"
                  }}
                  className={styles.contentContainer}
                >
                  <Block top={4}>
                    {item?.title && <p className="body-quote font-family-medium">{item.title}</p>}
                  </Block>
                  <Block top={2}>
                    {item?.description && <p className="body-text">{item.description}</p>}
                  </Block>

                  {item?.button?.text && (
                    <Block mobileTop={5} top={6}>
                      <Button
                        link={item?.button?.href}
                        secondary={item?.button?.secondary}
                        primary={item?.button?.primary}
                      >
                        {item?.button?.text}
                      </Button>
                    </Block>
                  )}
                </div>
              </Block>
            </Block>
          );
        })}
      </Grid>
    </Block>
  );
};

export default ThreeColumns;
