import React from "react";

import styles from "./Flow.module.scss";

type FlowProps = {
  blockContent?: boolean;
  children: React.ReactNode;
};

const Flow = ({ blockContent, children }: FlowProps) => {
  // If used on a Sanity block content
  if (blockContent) {
    return <div className={styles.flowBlockContent}>{children}</div>;
  }

  return <div className={styles.flow}>{children}</div>;
};

export default Flow;
