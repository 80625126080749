"use client";
import Link from "next/link";

import Image from "/components/cms/image";
import Button from "components/Button";
import { Block } from "components/Layout";
import PortableText from "/components/cms/portableText";

import isEmptyObject from "/utils/isEmptyObject";

import styles from "./HeroFullScreenImage.module.scss";
import { PortableTextBlock } from "@portabletext/types";
import { ImageObject } from "/lib/sanity";

type Props = {
  data: {
    image: ImageObject;
    button: { text: string; href: string; primary: boolean };
    richText: { richText: PortableTextBlock[] };
  };
};

const HeroFullScreenImage = ({ data }: Props) => {
  const { image, button, richText } = data || {};

  return (
    <>
      <div className={styles.container}>
        <Image imageObject={image} imageLoading="eager" />

        <div className={styles.titleContainer}>
          <Block mobileTop={1} top={10}>
            {richText?.richText && <PortableText blocks={richText?.richText} />}
          </Block>
        </div>

        {!isEmptyObject(button) && (
          <div className={styles.buttonContainer}>
            <Block top={6}>
              {button?.primary ? (
                <Button link={button.href} primary={button.primary}>
                  <span className="text-button">{button.text}</span>
                </Button>
              ) : (
                <Link href={button.href}>
                  <p className="link">
                    <strong>{button?.text}</strong>
                  </p>
                </Link>
              )}
            </Block>
          </div>
        )}
      </div>
    </>
  );
};

export default HeroFullScreenImage;
