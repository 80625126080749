"use client";
import { useEffect, useRef, useState } from "react";

import styles from "./Video.module.scss";

type VideoProps = {
  url: string;
  posterImageUrl: string;
};

const VideoFrontPage = ({ url, posterImageUrl }: VideoProps) => {
  const videoRef = useRef(null);
  const [isNotMobile, setIsNotMobile] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    setIsNotMobile(window?.innerWidth > 768);
  }, []);

  const handlePlayVideo = () => {
    // @ts-ignore
    videoRef?.current.play();
    setIsPlaying(true);
  };

  if (!url || !posterImageUrl) {
    return null;
  }

  return (
    <div className={styles.mediaContainer}>
      {!isPlaying && !isNotMobile && (
        <div className={styles.playButtonContainer}>
          <div className={styles.playButton} onClick={handlePlayVideo}>
            <svg
              width="150"
              height="150"
              viewBox="0 0 150 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 111.023L17 43H75.5V52.5233C75.5 84.8319 49.3087 111.023 17 111.023V111.023Z"
                fill="#ffffff"
              />
              <mask
                id="mask0_602_132"
                maskUnits="userSpaceOnUse"
                x="17"
                y="43"
                width="59"
                height="69"
              >
                <path
                  d="M17 111.023L17 43H75.5V52.5233C75.5 84.8319 49.3087 111.023 17 111.023V111.023Z"
                  fill="#ffffff"
                />
              </mask>
              <g mask="url(#mask0_602_132)">
                <path
                  d="M45.2297 75.311C31.9651 77.6918 19.3808 101.5 17 111.023L9.51746 102.52L9.51746 43.6802C25.3896 48.4418 60.1625 72.6308 45.2297 75.311Z"
                  fill="#FFEEE5"
                />
              </g>
              <path
                d="M134 111.023V43L75.5 43V52.5233C75.5 84.8319 101.691 111.023 134 111.023V111.023Z"
                fill="#D36861"
              />
              <mask
                id="mask1_602_132"
                maskUnits="userSpaceOnUse"
                x="75"
                y="43"
                width="59"
                height="69"
              >
                <path
                  d="M134 111.023V43L75.5 43V52.5233C75.5 84.8319 101.691 111.023 134 111.023V111.023Z"
                  fill="#BA6451"
                />
              </mask>
              <g mask="url(#mask1_602_132)">
                <path
                  d="M105.77 75.311C119.035 77.6919 131.619 101.5 134 111.023L141.483 102.52V43.6802C125.61 48.4419 90.8375 72.6308 105.77 75.311Z"
                  fill="#FFEEE5"
                />
              </g>
              <rect width="150" height="150" rx="75" fill="#FFBAB6" />
              <rect x="25" y="70" width="30" height="30" rx="15" fill="black" />
              <path
                d="M26 61.5C35.5 61.5 43 53 43 45"
                stroke="black"
                strokeWidth="11"
                strokeLinecap="round"
              />
              <rect
                width="30"
                height="30"
                rx="15"
                transform="matrix(-1 0 0 1 124 70)"
                fill="black"
              />
              <path
                d="M123 61.5C113.5 61.5 106 53 106 45"
                stroke="black"
                strokeWidth="11"
                strokeLinecap="round"
              />
              <circle cx="75" cy="75" r="75" fill="#FFD7C0" />
              <path
                d="M102.5 79.3301C105.833 77.4056 105.833 72.5944 102.5 70.6699L65 49.0192C61.6667 47.0947 57.5 49.5004 57.5 53.3494V96.6506C57.5 100.5 61.6667 102.905 65 100.981L102.5 79.3301Z"
                fill="#FFFCF4"
              />
            </svg>
          </div>
        </div>
      )}

      <video
        autoPlay={isNotMobile}
        ref={videoRef}
        playsInline
        poster={posterImageUrl}
        className={styles.video}
        preload="auto"
        src={url}
        loop
        muted
      />
    </div>
  );
};

export default VideoFrontPage;
