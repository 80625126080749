"use client";

import { PortableText as PBText, PortableTextComponents } from "@portabletext/react";
import { PortableTextBlock } from "@portabletext/types";

import Link from "next/link";

import { dataset as sanityDataset, projectId as sanityProjectId } from "/lib/sanity/client";
import Image from "./image";
import { ReactNode } from "react";

/**
 * docs from sanity: https://www.sanity.io/docs/portable-text-to-react
 */
const components: PortableTextComponents = {
  block: {
    ["body-quote"]: ({ children }: { children?: ReactNode }) => {
      return <p className="body-quote">{children}</p>;
    }
  },
  types: {
    image: ({ value }) => {
      return <Image imageObject={value} />;
    }
  },
  marks: {
    link: ({ value, children }) => {
      const href = value?.href || "";

      // We add full url only for external links
      const isExternalLink = value?.href?.includes("http");

      // External
      if (isExternalLink) {
        return (
          <a href={value.href} target="_blank" rel="noopener noreferrer" className="link">
            {children}
          </a>
        );
      }

      // Email adress
      if (href.includes("mailto:")) {
        return (
          <a href={href} className="link">
            {children}
          </a>
        );
      }

      // Internal - Add Next.js Link wrapper
      if (value?.href) {
        return (
          <Link className="link" href={value.href}>
            {children}
          </Link>
        );
      }

      return <></>;
    },
    highlight: ({ children }) => {
      // External
      return <span className="highlight">{children}</span>;
    }
  },
  list: {
    bullet: ({ children }) => <ul className="ul-list">{children}</ul>,
    number: ({ children }) => <ol className="ol-list">{children}</ol>
  }
};

const PortableText = ({ blocks }: { blocks: PortableTextBlock[] }) => {
  return <PBText value={blocks} components={components} />;
};

export default PortableText;
