"use client";
import PropTypes from "prop-types";

import { Grid, Block } from "components/Layout";
import PortableText from "/components/cms/portableText";

import classNames from "classnames";

import styles from "./TwoColumnsRectangleAndCircle.module.scss";

const TwoColumnsRectangleAndCircle = ({ data }) => {
  const { title, underText, listItems } = data;

  return (
    <div>
      <Block bottom={8}>
        <h2 align="center">{title}</h2>
      </Block>
      <Grid>
        {listItems.map((item) => {
          const isCircleElement = item._type === "fargetSirkel";
          const flex = isCircleElement ? 1 : 2;

          const boxClass = classNames({
            [styles.boxItem]: true,
            [styles[`boxBgColor-${item.bgColor}`]]: item.bgColor,
            [styles.circle]: isCircleElement
          });

          return (
            <Block key={item._key} style={{ flex }} blockContent className={boxClass}>
              <article>
                <PortableText blocks={item.richText.richText} />
              </article>
            </Block>
          );
        })}
      </Grid>
      <Block top={6}>
        <article>
          <PortableText blocks={underText?.richText?.richText} />
        </article>
      </Block>
    </div>
  );
};

TwoColumnsRectangleAndCircle.propTypes = {
  data: PropTypes.shape({
    richText: PropTypes.array,
    styledList: PropTypes.object,
    underText: PropTypes.object,
    listItems: PropTypes.array,
    title: PropTypes.string
  }).isRequired
};

export default TwoColumnsRectangleAndCircle;
