"use client";
import { useState, useEffect } from "react";
import classNames from "classnames";
import Link from "next/link";

import isEmptyObject from "utils/isEmptyObject";
import sleep from "utils/sleep";

import Spinner from "../Spinner";
import styles from "./Button.module.scss";

export type ButtonProps = {
  type?: string;
  link?: string;
  primary?: boolean;
  secondary?: boolean;
  disabled?: boolean;
  mini?: boolean;
  isSubmitting?: boolean;
  errors?: {};
  menu?: boolean;
  onClick?: any;
  children: any;
  color?: "green" | "red";
  scroll?: boolean;
  style?: any;
};

const Button = ({
  type,
  link,
  primary = true,
  scroll = false,
  secondary,
  menu,
  mini,
  disabled,
  isSubmitting,
  errors,
  onClick,
  color,
  children,
  ...rest
}: ButtonProps) => {
  const [isErrorShake, setIsErrorShake] = useState(false);
  // Trigger shake-animation on submit button if errors
  useEffect(() => {
    const toggleErrorShake = async () => {
      if (errors && !isEmptyObject(errors)) {
        setIsErrorShake(false);
        await sleep(50); // A delay is needed to re-animate with css classes
        setIsErrorShake(true);
      }
    };
    toggleErrorShake();
  }, [errors]);

  const buttonClass = classNames({
    [styles.button]: true,
    [styles[`button-primary`]]: primary,
    [styles[`button-secondary`]]: secondary,
    [styles[`button-menu`]]: menu,
    [styles[`error-shake`]]: isErrorShake,
    [styles[`disabled`]]: disabled,
    [styles[`button-mini`]]: mini,
    [styles[`button-color-${color}`]]: color
  });

  // Submit button
  if (type === "submit") {
    return (
      <button type="submit" className={buttonClass} disabled={disabled} onClick={onClick} {...rest}>
        {children}
        {isSubmitting && <Spinner />}
      </button>
    );
  }

  // Button
  if (type === "button") {
    return (
      <button type="button" disabled={disabled} className={buttonClass} onClick={onClick} {...rest}>
        {children}
        {isSubmitting && <Spinner />}
      </button>
    );
  }

  if (!link) {
    return null;
  }

  return (
    <Link scroll={scroll} href={link}>
      <div className={buttonClass} {...rest}>
        {children}
      </div>
    </Link>
  );
};

export default Button;
