"use client";
import classNames from "classnames";

import styles from "./Flex.module.scss";

type FlexProps = {
  align?: "start" | "center" | "end";
  justify?: "start" | "center" | "end" | "spaceBetween" | "spaceAround";
  wrap?: boolean;
  children: React.ReactNode;
};
const Flex = ({ align = "center", justify = "start", wrap = false, children }: FlexProps) => {
  const flexClass = classNames({
    [styles.flex]: true,
    [styles[`-align-${align}`]]: align,
    [styles[`-justify-${justify}`]]: justify,
    [styles["-wrap"]]: wrap
  });

  return <div className={flexClass}>{children}</div>;
};

export default Flex;
