"use client";
import Link from "next/link";
import classNames from "classnames";

import Button from "components/Button";
import { Block } from "components/Layout";
import PortableText from "/components/cms/portableText";
import VideoFrontPage from "/components/Video/VideoFrontPage";

import styles from "./FrontPageHero.module.scss";
import { PortableTextBlock } from "@portabletext/types";

type HeroProps = {
  data: {
    richText: { richText: PortableTextBlock[]; centerText: boolean };
    video: { videoUrl: string; posterImageUrl: string };
    button: { text: string; href: string; primary: boolean };
  };
};

const Hero = ({ data }: HeroProps) => {
  const {
    button,
    video,
    richText = {
      centerText: false,
      richText: []
    }
  } = data;

  const contentClass = classNames({
    [styles.content]: true,
    [styles[`content-center`]]: richText?.centerText
  });

  return (
    <div className={styles.container}>
      <VideoFrontPage posterImageUrl={video?.posterImageUrl} url={video?.videoUrl} />
      <div className={contentClass}>
        {richText && <PortableText blocks={richText.richText} />}

        {button?.href && (
          <Block top={6}>
            {button?.primary ? (
              <Button link={button.href} primary={button.primary}>
                {button.text}
              </Button>
            ) : (
              <Link className="link" href={button.href}>
                {button?.text}
              </Link>
            )}
          </Block>
        )}
      </div>
    </div>
  );
};

export default Hero;
