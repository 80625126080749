"use client";
import CardGrid from "/components/CardGrid";
import Card from "/components/Card";

type Props = {
  data: {
    reverseFirst: boolean;
    alternating: boolean;
    listItem: Array<{ _key: string }>;
  };
};

const ListWithImages = ({ data }: Props) => {
  return (
    <CardGrid reverseFirst={data.reverseFirst} reverse={data.alternating}>
      {data.listItem.map((item: { _key: string }) => (
        <Card data={item} key={item._key} />
      ))}
    </CardGrid>
  );
};

export default ListWithImages;
