"use client";
import PropTypes from "prop-types";
import Link from "next/link";

import Image from "/components/cms/image";
import Button from "components/Button";
import { Flow, Block, Grid } from "components/Layout";

const HeroWithTitle = ({ data = {} }) => {
  const { image = {}, intro = "", button = {}, title = "" } = data;
  const imageWidth = "800";

  return (
    <>
      <Grid reverse verticalCenter>
        <Image imageObject={image} maximagewidth={imageWidth} imageLoading="eager" />

        <div>
          <Flow>
            {title && <h2 className="h1">{title}</h2>}

            {intro && <p className="h2">{intro}</p>}

            {button.href && (
              <Block top={6}>
                {button?.primary ? (
                  <Button link={button.href} primary={button.primary}>
                    <span className="text-button">{button.text}</span>
                  </Button>
                ) : (
                  <Link href={button.href}>
                    <p className="link">
                      <strong>{button?.text}</strong>
                    </p>
                  </Link>
                )}
              </Block>
            )}
          </Flow>
        </div>
      </Grid>
    </>
  );
};

HeroWithTitle.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object,
    intro: PropTypes.string,
    button: PropTypes.object
  }).isRequired
};

export default HeroWithTitle;
